<template>
  <div>
    <el-page-header
      :content="getTitle('header')"
      class="cashback-pageHeader"
      @back="goBack"
    />

    <el-button
      class="cashback-operation-btn"
      icon="el-icon-plus"
      type="primary"
      @click="
        action === 'create'
          ? createCashBack()
          : action === 'view'
          ? editCashback()
          : editCashbackClicked()
      "
      size="medium"
      :disabled="
        action === 'create' && cashbackFormData.cashbackConfigs.length === 0
      "
    >
      {{ getTitle("button") }}
    </el-button>
    <el-form
      :rules="formRules"
      :model="cashbackFormData"
      ref="cashbackForm"
      :disabled="mode === 'view'"
    >
      <el-card class="box-card" style="margin-bottom: 10px">
        <div slot="header" class="clearfix">
          <h3 style="margin: unset; display: inline-block">
            Cashback Fixed Values
          </h3>
        </div>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :label="`Start Date`"
              prop="startDate"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-date-picker
                :disabled="
                  isStartDatePassed(originalCashbackFormData.startDate)
                "
                v-model="cashbackFormData.startDate"
                type="datetime"
                placeholder="Select date and time"
                value-format="timestamp"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="`End Date`"
              prop="endDate"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-date-picker
                v-model="cashbackFormData.endDate"
                type="datetime"
                placeholder="Select date and time"
                value-format="timestamp"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="`Expiration Period`"
              prop="expirationPeriod"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-input
                :disabled="
                  isStartDatePassed(originalCashbackFormData.startDate)
                "
                type="number"
                autocomplete="off"
                v-model="cashbackFormData.expirationPeriod"
                placeholder="Select Expiration Period"
                style="width: 100%"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="cashbackFormData.type == 'provider' ? 12 : 24">
            <!-- <el-col :span="12"> -->
            <el-form-item
              label="Payment Method"
              prop="paymentMethod"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-radio-group
                v-model="cashbackFormData.paymentMethod"
                :disabled="
                  isStartDatePassed(originalCashbackFormData.startDate)
                "
              >
                <el-radio label="cash">Cash</el-radio>
                <el-radio label="credit">Credit</el-radio>
                <el-radio label="both">Both</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <template v-if="cashbackFormData.type == 'provider'">
              <el-form-item
                prop="source"
                label="Promotion Type"
                label-width="200px"
              >
                <el-select
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  style="width: 100%"
                  v-model="cashbackFormData.source"
                  filterable
                  placeholder="Choose Promotion Type"
                >
                  <el-option label="Fatura" value="fatura"> </el-option>
                  <el-option label="Wholesaler" value="whs"> </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="Type"
              label-width="200px"
              style="flex-basis: 50%"
            >
              <el-radio-group
                v-model="cashbackFormData.type"
                size="medium"
                :disabled="action !== 'create'"
              >
                <el-radio-button label="general">General</el-radio-button>
                <el-radio-button label="provider">Provider</el-radio-button>
                <el-radio-button label="tNumber">T-Number</el-radio-button>
                <el-radio-button
                  label="bundle"
                  :disabled="cashbackFormData.paymentMethod !== 'credit'"
                  >Bundle</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- Provider -->
            <template v-if="cashbackFormData.type == 'provider'">
              <el-form-item
                prop="entityIds"
                label="Select Provider"
                label-width="200px"
              >
                <el-select
                  :disabled="action !== 'create'"
                  style="width: 100%"
                  v-model="cashbackFormData.entityIds"
                  v-if="lookups"
                  multiple
                  filterable
                  placeholder="Select Providers"
                  clearable
                >
                  <el-option
                    v-for="item in lookups.distributers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- Provider -->
            <!-- Bundle -->
            <template v-if="cashbackFormData.type == 'bundle'">
              <el-form-item
                prop="bundleIds"
                label="Select Bundle"
                label-width="200px"
              >
                <el-select
                  :disabled="action !== 'create'"
                  style="width: 100%"
                  v-model="cashbackFormData.bundleIds"
                  v-if="lookups"
                  multiple=""
                  filterable
                  placeholder="Select Bundle"
                  clearable
                >
                  <el-option
                    v-for="item in lookups.bundles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- Bundle -->
            <!-- tNumber -->
            <template v-if="cashbackFormData.type == 'tNumber'">
              <el-form-item
                prop="tNumbers"
                label="Transaction Numbers"
                label-width="200px"
              >
                <template
                  v-for="(transaction, index) in cashbackFormData.tNumbers"
                >
                  <el-tag
                    v-if="action === 'view' || action === 'edit'"
                    class="tNumberHolder"
                    type="success"
                    :key="index"
                  >
                    {{ transaction }}
                  </el-tag>
                  <el-tag
                    class="tNumberHolder"
                    type="success"
                    :key="index"
                    closable
                    @close="removeTransaction(index)"
                    v-else
                  >
                    {{ transaction }}
                  </el-tag>
                </template>
                <el-input
                  v-if="action === 'create'"
                  style="margin-top: 6px"
                  v-model="transactionInput"
                  placeholder="Enter transaction numbers separated by comma"
                  @change="addTransactions"
                  @input="handleInput"
                ></el-input>
              </el-form-item>
            </template>
            <!-- tNumber -->
          </el-col>
        </el-row>
        <!-- Industries -->
        <el-row>
          <el-col :span="12">
            <template v-if="cashbackFormData.type != 'provider'">
              <el-form-item
                prop="industryId"
                label="Select Industry"
                label-width="200px"
              >
                <el-select
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  style="width: 100%"
                  v-model="cashbackFormData.industryId"
                  v-if="lookups"
                  filterable
                  placeholder="Select Industry"
                  clearable
                >
                  <el-option
                    v-for="item in lookups.cashbackIndustries"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-col>

          <!-- Industries -->
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix cashbackDetailsHeader">
          <h3 style="margin: unset; display: inline-block">Cashback Details</h3>
          <el-button
            v-if="action === 'create'"
            size="medium"
            icon="el-icon-plus"
            type="primary"
            @click="addNewConfig"
            >Add Cashback</el-button
          >
        </div>
        <div
          class="cashbackDetailsWrapper"
          v-for="(config, index) in cashbackFormData.cashbackConfigs"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Transaction Value From"
                :prop="'cashbackConfigs[' + index + '].minTransactionAmount'"
              >
                <el-input
                  @input="revalidate(index)"
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  placeholder="Enter Range From"
                  type="number"
                  v-model="config.minTransactionAmount"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Transaction Value To"
                :prop="'cashbackConfigs[' + index + '].maxTransactionAmount'"
              >
                <el-input
                  @input="revalidate(index)"
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  placeholder="Enter Range To"
                  type="number"
                  v-model="config.maxTransactionAmount"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Cashback Type"
                :prop="'cashbackConfigs[' + index + '].cashbackType'"
              >
                <el-radio-group
                  v-model="config.cashbackType"
                  size="medium"
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                >
                  <el-radio-button label="fixed">Fixed Rate</el-radio-button>
                  <el-radio-button label="percentage"
                    >Percentage</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Cashback Value"
                :prop="'cashbackConfigs[' + index + '].cashbackAmount'"
              >
                <el-input
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  placeholder="Enter Cashback Value"
                  type="number"
                  v-model="config.cashbackAmount"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="config.cashbackType === 'percentage'">
              <el-form-item
                label-width="200px"
                style="flex-basis: 50%"
                label="Capping Amount"
                :prop="'cashbackConfigs[' + index + '].cappingAmount'"
              >
                <el-input
                  :disabled="
                    isStartDatePassed(originalCashbackFormData.startDate)
                  "
                  placeholder="Enter Cashback Capping Amount"
                  type="number"
                  v-model="config.cappingAmount"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" style="display: flex; justify-content: flex-end">
              <el-form-item v-if="action === 'create'">
                <el-button type="danger" @click="removeConfig(index)"
                  >Delete</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import { utils } from "@/utils";
import { mapGetters } from "vuex";
import { cashbackService } from "@/services/cashback.service.js";
import moment from "moment";
export default {
  name: "SingleCashback",
  components: {},
  created() {
    const { action = "", id = "", type = "" } = this.$route.params;
    this.action = action;
    this.cashbackId = id;
    this.cashbackType = type;
  },
  data() {
    const validateDate = (rule, value, callback) => {
      const nowUnixTimeStamp = moment().valueOf();
      const valueUnixTimeStamp = moment(value).valueOf();

      if (valueUnixTimeStamp < nowUnixTimeStamp) {
        callback(
          new Error("Please select a date greater than the current date.")
        );
      } else {
        callback();
      }
    };
    const validateDateComparedToStartDate = (rule, value, callback) => {
      const startDate = moment(this.cashbackFormData.startDate).valueOf();
      const valueUnixTimeStamp = moment(value).valueOf();

      if (valueUnixTimeStamp < startDate) {
        callback(
          new Error("Please select a date greater than the start date.")
        );
      } else {
        callback();
      }
    };
    return {
      mode: null,
      isActiveCashback: false,
      transactionInput: "",
      cashbackFormData: {
        entityIds: [],
        bundleIds: [],
        industryId: null,
        type: "general",
        tNumbers: [],
        cashbackConfigs: [
          {
            minTransactionAmount: undefined,
            maxTransactionAmount: undefined,
            cashbackType: undefined,
            cashbackAmount: undefined,
            cappingAmount: undefined,
          },
        ],
      },
      originalCashbackFormData: {},
      action: "",
      cashbackId: "",
      cashbackType: "",
      rules: {
        expirationPeriod: [
          {
            message: "Please enter Expiration Period",
            required: true,
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("Please enter Expiration Period"));
              } else if (!/^[1-9]\d*$/.test(value)) {
                callback(
                  new Error(
                    "Expiration Period must be a positive number greater than zero"
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        tNumbers: [
          {
            trigger: "blur",
            required: true,
            message: "Please Select The Providers",
          },
        ],
        entityIds: [
          {
            trigger: "change",
            required: true,
            message: "Please Select The Providers",
          },
        ],
        bundleIds: [
          {
            trigger: "change",
            required: true,
            message: "Please Select The Bundle",
          },
        ],
        paymentMethod: [
          {
            required: true,
            message: "Please Select The Payment Method",
          },
        ],
        source: [
          {
            required: true,
            message: "Please Select The Promotion Type",
          },
        ],
        startDate: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
          {
            validator: validateDate,
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
          {
            validator: validateDate,
            trigger: "blur",
          },
          {
            validator: validateDateComparedToStartDate,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    formRules() {
      const rules = { ...this.rules };
      this.cashbackFormData?.cashbackConfigs?.forEach((config, index) => {
        rules["cashbackConfigs[" + index + "].minTransactionAmount"] = [
          {
            required: true,
            message: "Please input Min Transaction Amount",
            trigger: "blur",
          },
          { validator: this.positiveValue, trigger: "blur" },
          { validator: this.checkEqual, trigger: "blur" },
        ];
        rules["cashbackConfigs[" + index + "].maxTransactionAmount"] = [
          {
            required: true,
            message: "Please input Max Transaction Amount",
            trigger: "blur",
          },
          { validator: this.positiveValue, trigger: "blur" },
          { validator: this.checkEqual, trigger: "blur" },
        ];
        rules["cashbackConfigs[" + index + "].cashbackType"] = [
          {
            required: true,
            message: "Please select Cashback Type",
            trigger: "change",
          },
        ];
        rules["cashbackConfigs[" + index + "].cashbackAmount"] = [
          {
            required: true,
            message: "Please input Cashback Amount",
            trigger: "blur",
          },
          { validator: this.positiveValue, trigger: "blur" },
        ];
        rules["cashbackConfigs[" + index + "].cappingAmount"] = [
          {
            required: false,
          },
          { validator: this.positiveValue, trigger: "blur" },
        ];
      });
      return rules;
    },
  },
  methods: {
    // ** Validation Methods ** //
    revalidate(index) {
      this.$refs["cashbackForm"].validateField(
        `cashbackConfigs[${index}].minTransactionAmount`
      );
      this.$refs["cashbackForm"].validateField(
        `cashbackConfigs[${index}].maxTransactionAmount`
      );
    },
    positiveValue(rule, value, callback) {
      if (value && value <= 0) {
        callback(new Error("Please input positive value"));
      } else {
        callback();
      }
    },

    checkEqual(rule, value, callback) {
      const field = rule.field;
      const startIndex = field.indexOf("[");
      const endIndex = field.indexOf("]");
      const index = parseInt(field.substring(startIndex + 1, endIndex));

      const { minTransactionAmount, maxTransactionAmount } =
        this.cashbackFormData.cashbackConfigs[index];

      if (
        +minTransactionAmount < +maxTransactionAmount &&
        +minTransactionAmount !== +maxTransactionAmount
      ) {
        callback();
      } else {
        if (+minTransactionAmount > +maxTransactionAmount) {
          callback(new Error("Max amount must be greater than min amount"));
        } else {
          if (maxTransactionAmount)
            callback(
              new Error("max and min transaction amounts cannot be equal")
            );
        }
      }
    },
    // ** Validation Methods ** //

    isStartDatePassed(startDate) {
      const startMoment = moment(startDate);
      const currentDate = moment();
      this.isActiveCashback = startMoment.isBefore(currentDate);
      return startMoment.isBefore(currentDate) && this.action !== "create";
    },

    addNewConfig() {
      this.cashbackFormData.cashbackConfigs.push({
        minTransactionAmount: null,
        maxTransactionAmount: null,
        cashbackType: "",
        cashbackAmount: null,
        cappingAmount: null,
      });
    },
    removeConfig(index) {
      this.cashbackFormData.cashbackConfigs.splice(index, 1);
    },

    handleInput() {
      // Regular expression to match only numbers and commas
      const regex = /^[0-9,]*$/;

      // Filter out invalid characters from the input
      this.transactionInput = this.transactionInput
        .split("")
        .filter((char) => regex.test(char) || char === ",")
        .join("");
    },
    addTransactions() {
      const transactions = this.transactionInput
        .split(",")
        .map((item) => item.trim());
      this.cashbackFormData.tNumbers = this.cashbackFormData.tNumbers.concat(
        transactions.filter((transaction) => transaction !== "")
      );
      this.transactionInput = "";
    },
    removeTransaction(index) {
      this.cashbackFormData.tNumbers.splice(index, 1);
    },

    getTitle(placement) {
      const titles = {
        view: placement === "header" ? "Cashback Details" : "Edit",
        create: placement === "header" ? "Create Cashback" : "Save",
        edit: placement === "header" ? "Edit Cashback" : "Save",
      };
      return titles[this.action] || "Unknown Action";
    },
    goBack() {
      this.$router.push("/cashback/management/");
    },
    buildRequestBody(data) {
      if (this.action == "edit" && data) {
        const hasCashbackConfigs = Array.isArray(data.cashbackConfigs);
        return {
          industryId:
            this.cashbackFormData?.type !== "provider" && data?.industryId
              ? data?.industryId
              : null,
          startDate: data?.startDate ? data?.startDate : undefined,
          endDate: data?.endDate ? data?.endDate : undefined,
          expirationPeriod: data?.expirationPeriod
            ? data?.expirationPeriod
            : undefined,
          paymentMethod: data?.paymentMethod ? data?.paymentMethod : undefined,
          source: data?.source ? data?.source : undefined,
          cashbackType: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.cashbackType
            : undefined,
          minTransactionAmount: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.minTransactionAmount
            : undefined,
          maxTransactionAmount: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.maxTransactionAmount
            : undefined,
          cashbackAmount: hasCashbackConfigs
            ? data?.cashbackConfigs[0]?.cashbackAmount
            : undefined,
          cappingAmount:
            hasCashbackConfigs &&
            this.cashbackFormData?.cashbackConfigs[0]?.cashbackType ===
              "percentage"
              ? data?.cashbackConfigs[0]?.cappingAmount
              : undefined,
        };
      }
      if (this.action == "create") {
        return {
          ...this.cashbackFormData,
          tNumbers:
            this.cashbackFormData?.tNumbers?.length === 0 ||
            this.cashbackFormData?.type !== "tNumber"
              ? undefined
              : this.cashbackFormData?.tNumbers,
          entityIds:
            this.cashbackFormData?.entityIds?.length === 0 ||
            this.cashbackFormData?.type !== "provider"
              ? undefined
              : this.cashbackFormData?.entityIds,
          bundleIds:
            this.cashbackFormData?.bundleIds?.length === 0 ||
            this.cashbackFormData?.type !== "bundle"
              ? undefined
              : this.cashbackFormData?.bundleIds,
          industryId:
            this.cashbackFormData?.type !== "provider" &&
            this.cashbackFormData?.industryId
              ? this.cashbackFormData?.industryId
              : null,
        };
      }
      return;
    },
    async createCashBack() {
      this.$refs["cashbackForm"].validate(async (isValid) => {
        try {
          if (isValid) {
            const apiObject = this.buildRequestBody();
            const response = await cashbackService.createCashback(apiObject);
            if (response && response.status == 201) {
              this.$globalFunctions.popupMessageWrapper(
                "Cashback created successfully",
                "success",
                1500
              );
              this.$router.push("/cashback/management/");
            }
          }
        } catch (error) {
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
    },
    async getCashbackInfo() {
      try {
        const response = await cashbackService.fetchCashBackInfo({
          cashbackType: this.cashbackType,
          cashbackId: this.cashbackId,
        });
        this.insertCashbackInfo(response.data.data);
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    constructCashbackFormData(data) {
      const {
        maxTransactionAmount,
        minTransactionAmount,
        cashbackAmount,
        cappingAmount,
        cashbackType,
        entityId,
        transactionNumber,
        bundleId,
        ...rest
      } = data;

      const cashbackConfigs = [
        {
          maxTransactionAmount,
          minTransactionAmount,
          cashbackAmount,
          cappingAmount,
          cashbackType,
        },
      ];

      return {
        ...rest,
        type: this.cashbackType,
        cashbackConfigs,
        entityIds: [...this.cashbackFormData.entityIds, entityId],
        tNumbers: [...this.cashbackFormData.tNumbers, transactionNumber],
        bundleIds: [...this.cashbackFormData.bundleIds, bundleId],
      };
    },
    insertCashbackInfo(data) {
      this.cashbackFormData = this.constructCashbackFormData(data);
      this.insertCashbackInfoOriginal(data);
    },
    insertCashbackInfoOriginal(data) {
      this.originalCashbackFormData = this.constructCashbackFormData(data);
    },
    compareCashbackFormData(originalObj, changedObj) {
      const changes = {};
      for (const key in originalObj) {
        if (originalObj.hasOwnProperty(key) && changedObj.hasOwnProperty(key)) {
          // Compare individual properties
          if (
            JSON.stringify(originalObj[key]) !== JSON.stringify(changedObj[key])
          ) {
            changes[key] = changedObj[key];
          }
        }
      }

      // Compare cashbackConfigs array of objects
      const originalConfigs = originalObj.cashbackConfigs || [];
      const changedConfigs = changedObj.cashbackConfigs || [];
      const configChanges = [];

      for (let i = 0; i < originalConfigs.length; i++) {
        const originalConfig = originalConfigs[i];
        const changedConfig = changedConfigs[i];

        // Check if both original and changed configurations exist
        if (originalConfig && changedConfig) {
          // Compare individual properties of the configuration
          const individualChanges = {};
          for (const key in originalConfig) {
            if (
              originalConfig.hasOwnProperty(key) &&
              changedConfig.hasOwnProperty(key)
            ) {
              if (
                JSON.stringify(originalConfig[key]) !==
                JSON.stringify(changedConfig[key])
              ) {
                individualChanges[key] = changedConfig[key];
              }
            }
          }
          // If there are changes in the configuration, add them to the changes array
          if (Object.keys(individualChanges).length > 0) {
            configChanges.push(individualChanges);
          }
        }
      }

      // If there are changes in cashbackConfigs, add them to the main changes object
      if (configChanges.length > 0) {
        changes.cashbackConfigs = configChanges;
      }
      delete changes.bundleIds;
      delete changes.entityIds;
      delete changes.tNumbers;
      if (Object.keys(changes).length === 0) {
        return null;
      }
      return changes;
    },

    editCashback() {
      this.$router.push({
        name: "SingleCashback",
        params: {
          action: "edit",
          id: this.cashbackId,
          type: this.cashbackType,
        },
      });
      this.mode = "edit";
    },
    editCashbackClicked() {
      if (!this.isActiveCashback) {
        this.$refs["cashbackForm"].validate(async (isValid) => {
          try {
            if (isValid) {
              const apiObject = this.buildRequestBody(
                this.compareCashbackFormData(
                  this.originalCashbackFormData,
                  this.cashbackFormData
                )
              );
              if (!apiObject) {
                this.$globalFunctions.popupMessageWrapper(
                  "No Changes were made to update the cashback.",
                  "error",
                  3500
                );
                return;
              }

              const response = await cashbackService.editCashback({
                formData: apiObject,
                cashbackId: this.cashbackId,
                cashbackType: this.cashbackType,
              });
              if (response && response.status == 200) {
                this.$globalFunctions.popupMessageWrapper(
                  "Cashback edited successfully",
                  "success",
                  1500
                );
                this.$router.push("/cashback/management/");
              }
            }
          } catch (error) {
            const errorMessage =
              this.$globalFunctions.errorMessageExtractor(error);
            this.$globalFunctions.popupMessageWrapper(
              errorMessage,
              "error",
              3500
            );
          }
        });
        return;
      }
      this.$refs["cashbackForm"].validateField("endDate", async (error) => {
        try {
          if (!error) {
            const apiObject = this.buildRequestBody(
              this.compareCashbackFormData(
                this.originalCashbackFormData,
                this.cashbackFormData
              )
            );
            if (!apiObject) {
              this.$globalFunctions.popupMessageWrapper(
                "No Changes were made to update the cashback.",
                "error",
                3500
              );
              return;
            }

            const response = await cashbackService.editCashback({
              formData: apiObject,
              cashbackId: this.cashbackId,
              cashbackType: this.cashbackType,
            });
            if (response && response.status == 200) {
              this.$globalFunctions.popupMessageWrapper(
                "Cashback edited successfully",
                "success",
                1500
              );
              this.$router.push("/cashback/management/");
            }
          }
        } catch (error) {
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        }
      });
      // this.$refs["cashbackForm"].validateField("endDate", async (isValid) => {
    },
  },
  async mounted() {
    if (this.action === "view") {
      await this.getCashbackInfo();
      this.mode = "view";
    }
    if (this.action === "edit") {
      await this.getCashbackInfo();
    }
    this.$refs.cashbackForm.clearValidate();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$route.params.action"(newValue, oldValue) {
      this.action = newValue;
      this.mode = newValue;
    },
  },
};
</script>
<style scoped>
.cashbackDetailsWrapper:not(:last-child) {
  position: relative;
  margin-bottom: 52px;
}
.cashbackDetailsWrapper:not(:last-child):after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #cacaca;
  left: 0;
  bottom: -18px;
}
.tNumberHolder {
  position: relative;
  margin-right: 4px;
  padding-left: 22px;
}
.tNumberHolder:before {
  position: absolute;
  content: "T-";
  color: #67c23a;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.cashback-pageHeader {
  border-radius: 4px;
  padding: 12px;
  background: #fff;
  margin-bottom: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.cashback-operation-btn {
  display: block;
  margin-left: auto;
  margin-bottom: 12px;
}
.cashbackDetailsHeader {
  display: flex;
  justify-content: space-between;
}
</style>
